import { Input } from 'antd'
import Form from 'antd/lib/form/Form'
import { PlusSquareFilled, CloseOutlined } from '@ant-design/icons'

export const ChannelsController = ({
  buyerChannels,
  channelVal,
  setChannelVal,
  handleSetChannel,
  handleRemoveChannel,
}) => {
  return (
    <div className="col-12 col-lg-6 channels">
      <h1>Channel</h1>
      <div className="row">
        <div className="col-12">
          <div className="channels__controller">
            <div className="col-10">
              <Form.Item name="channel">
                <Input
                  value={channelVal}
                  onBlur={(e) => setChannelVal(e.target.value)}
                  type="text"
                  placeholder="Channel"
                  onSubmit={(e) => setChannelVal(e.target.value)}
                />
              </Form.Item>
            </div>
            <PlusSquareFilled
              className="channels__controller__btn"
              onClick={(e) => handleSetChannel(channelVal, buyerChannels)}
            />
          </div>
          <div className="row">
            <div className="col-12 channels__list">
              {buyerChannels.map((c) => (
                <div className="channels__list__card">
                  <p>{c}</p>
                  <CloseOutlined onClick={() => handleRemoveChannel(c, buyerChannels)} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'

const AuthLayout = ({ children }) => {
  return (
    <>
      <main className="secured-main">
        <div>{children}</div>
      </main>
    </>
  )
}

export default AuthLayout
